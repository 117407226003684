import '../stylesheets/metros_organic_critical.css'
import 'lazysizes'

if (document.getElementById('promo_auto_applied_banner')) {
    import(
      /* webpackChunkName: "promoAutoAppliedManager" */ 'promo_auto_applied_manager'
    ).then(module => {
      module.promoAutoAppliedManager();
    });
  }
